.home_wrapper {
  background: #fff;
}
.logo_text {
  color: #7d7d7d;
}

.ave_temp_txt {
  --background: "red";
  /* color: "#22A7F0"; */
  color: "red !important";
  font-size: 30;
}
.temp_info {
  color: "#777";
  margin: 20;
}

.notification_alert_icon {
  padding-top: 200px;
  color: red;
}

.notification_alert_icon_success {
  padding-top: 200px;
  color: rgb(96, 96, 96);
}

.notification_tittle {
  color: #274574;
}

.countN {
  background-color: #ffffff !important;
  border-left: 2px solid #a4be24;
  border-top: 2px solid #be2e24;
  color: rgb(172, 0, 0);
  margin: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;

  border-radius: 100px;
}

.btnLink {
  --background: #81818100;
  --box-shadow: none;
  --overflow: none;
}

.filterOptions {
  --background: #27457400;
  --box-shadow: none !important;
  --color: rgb(96, 96, 96) !important;
  padding-top: 23px;
}

.title_f {
  text-align: center;
  padding: 5%;
  color: rgb(121, 119, 119);
}
.main_row_date_filter {
  padding-left: 4%;
  padding-right: 4%;
  background-color: #cfcfcf2a;
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 20px;
}
.button_data_f {
  text-align: center;
  margin-top: 5%;
  margin-left: 15%;
  margin-right: 15%;
}
.btn_d {
  height: 50px;
  padding-bottom: 5px;
}
.m_head {
  box-shadow: none;
}
.df_modal {
  --border-radius: 20px;
}
.btn_date_filter {
  background-color: #27457400;
  --box-shadow: none;
  --border-radius: 10git 
  margin: 0;
  --padding: 0;
}
.date_icon {
  color: #7f7f7f;
}
.line_wrapper {
  background: #3510102a;
  width: fit-content;
  border-radius: 5px;
  margin: 5px;
}
.line_graph {
  display: flex;
  height: 5px;
  background: #cd9b9b;
  align-items: center;
  border-radius: 10px;
  margin: 10px;
}
.color {
  background: #8b95a4;
  width: 20px;
  height: 5px;
}
.dot {
  background: #274574;
  width: 20px;
  height: 20px;
  border-radius: 100px;
}

.temp_info{}

.gauge_style {
  width: 550px;
  height: 250px;
}
.ave {
  font-size: 180px
}
.ave_temp_text{
  font-size: 8rem
  
}
@media (max-width: 576px) { 
  .gauge_style {
    width: 250px; 
    height: 150px;
  } 
  .ave {
    font-size: 60px
  }
  .ave_temp_text{
    font-size: 4rem
    
  }
}
.modals{
  background-color: #a4be24;
    z-index: 10; /* Set a lower z-index for the modal */
}